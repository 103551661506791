import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import ArgonBox from "../../../components/ArgonBox";
import Table from "../../../components/Table";
import ArgonButton from "../../../components/ArgonButton";
import ArgonBadgeDot from "../../../components/ArgonBadgeDot";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { ManagementServices } from "../../../api/BackendApi/ManagementServices";
import { CircularProgress, tooltipClasses } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

function TableServices({ namespace, redeployService, getLogs }) {

  const mounted = useRef(false);
  const [allServices, setAllServices] = useState(null);
  const navigate = useNavigate();

  const goToServiceInformation = (service) => {
    navigate('/service_information', { state: { "namespace": namespace[0], "service": service } });
  }

  const goToOrchestratorSettings = (service) => {
    navigate('/orchestrator_settings', { state: { "namespace": namespace[0], "info": service } });
  }

  useEffect(() => {
    async function loadData() {
      mounted.current = true;
      await updateLoop();
    }
    loadData().then(r => console.log("Information loaded"));
  }, []);

  const updateLoop = async () => {
    try {
      if (!mounted.current) return;
      let response_services = await ManagementServices.show_all_services(namespace[0]);
      setAllServices(response_services.data);
    } finally {
      setTimeout(updateLoop, 5000);
    }
  }

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  let body = <Grid container justifyContent="center" sx={{ marginTop: 20, marginBottom: 20 }}>
    <CircularProgress></CircularProgress>
  </Grid>

  if (allServices != null) {
    body = (
      <ArgonBox mt={5} mb={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox>
              <Table
                columns={[
                  { name: "services", align: "center" },
                  { name: "app_name", align: "center" },
                  { name: "url", align: "center" },
                  { name: "port", align: "center" },
                  { name: "cluster_ip", align: "center" },
                  { name: "external_ip", align: "center" },
                  { name: "status", align: "center" },
                  { name: "actions", align: "center" },
                ]}
                rows={allServices.services.map((service) => {

                  let visible_action_settings = false
                  let visible_action_reload = true
                  let visible_action_log = true

                  if (service.service_name.includes("fe") || service.service_name.includes("be") ||
                    service.service_name.includes("hub") || service.service_name.includes("node") ||
                    service.service_name.includes("orchestrator") || service.service_name.includes("service")) {
                    visible_action_settings = true
                  }

                  if (service.service_name.includes("ingress")) {
                    visible_action_reload = false
                  }

                  if (service.service_name.includes("node") || service.service_name.includes("ingress")) {
                    visible_action_log = false
                  }

                  var appName = service.selector["app"] ? service.selector["app"] : "N/A";

                  var actions = [

                    <Grid item key={"restart"} xs={3} textAlign={"start"}>
                      <NoMaxWidthTooltip title="Restart" placement="top">
                        <ArgonButton variant="gradient" color="info" iconOnly
                          key={service.service_name}
                          sx={{ visibility: visible_action_reload ? "visible" : "hidden" }}
                          onClick={() => redeployService(service.service_name)}>
                          <Icon>refresh</Icon>
                        </ArgonButton>
                      </NoMaxWidthTooltip>
                    </Grid>,
                    <Grid item key={"logs"} xs={3} textAlign={"start"}>
                      <NoMaxWidthTooltip title="Logs" placement="top">
                        <ArgonButton variant="gradient" color="info" iconOnly
                          key={service.service_name}
                          sx={{ visibility: visible_action_log ? "visible" : "hidden" }}
                          onClick={() => getLogs(service.service_name)}>
                          <Icon>description</Icon>
                        </ArgonButton>
                      </NoMaxWidthTooltip>
                    </Grid>,
                    <Grid item key={"other-info"} xs={3} textAlign={"start"}>
                      <NoMaxWidthTooltip title="Other Info" placement="top">
                        <ArgonButton variant="gradient" color="info" iconOnly
                          key={service.service_name}
                          sx={{ visibility: visible_action_settings ? "visible" : "hidden" }}
                          onClick={() => goToServiceInformation(service.service_name)}>
                          <Icon>troubleshoot</Icon>
                        </ArgonButton>
                      </NoMaxWidthTooltip>
                    </Grid>

                  ]

                  if (appName == "taf-orchestrator") {
                    actions.push(<Grid item key={"orchestrator"} xs={3} textAlign={"start"}>
                      <NoMaxWidthTooltip title="Orchestrator Settings" placement="top">
                        <ArgonButton variant="gradient" color="info" iconOnly
                          key={service.service_name}
                          sx={{ visibility: visible_action_settings ? "visible" : "hidden" }}
                          onClick={() => goToOrchestratorSettings(service)}>
                          <Icon>settings</Icon>
                        </ArgonButton>
                      </NoMaxWidthTooltip>
                    </Grid>)
                  }

                  return {
                    services: service.service_name,
                    app_name: appName,
                    url: service.ingresses.length > 0 ?
                      (<ArgonButton variant="text" color="info" href={"https://" + service.ingresses[0].host} target="_blank">{service.ingresses[0].host}</ArgonButton>)
                      : "N/A",
                    port: service.ports[0].port,
                    cluster_ip: service.cluster_ip,
                    external_ip: service.external_ip,
                    status: (service.healthy === true ? (
                      <ArgonBox ml={-1.325}>
                        <ArgonBadgeDot size="lg" color="success" badgeContent={service.healthy} />
                      </ArgonBox>
                    ) : (
                      <ArgonBox ml={-1.325}>
                        <ArgonBadgeDot size="lg" color="error" badgeContent={service.healthy} />
                      </ArgonBox>
                    )),
                    actions: <Grid container spacing={1} columns={12} justifyContent={"space-between"}>{actions}</Grid>
                  }
                })}
              />
            </ArgonBox>
          </Card>
        </ArgonBox >
      </ArgonBox >
    );
  }

  return body

}

export default TableServices;
