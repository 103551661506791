import { Box, Card } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import colors from "assets/theme/base/colors";

const { white, info, primary, secondary, dark } = colors;


function LineChart({ metrics, height = '500px' }) {

    const [option, setOption] = useState(null)

    const [_data, setData] = useState(null)

    useEffect(() => {
        setData(metrics)
    }, [metrics])

    useEffect(() => {
        if (_data == null) {
            return;
        }

        setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                top: '0 px',
                data: ["CPU", "RAM"]
            },
            grid: {
                top: '25px',
                left: '50px',
                right: '75px',
                bottom: '20px',
                containLabel: false
            },
            xAxis: [
                {
                    type: "time",
                    axisLabel: {
                        formatter: function (value) {
                            return new Date(value).toLocaleTimeString(); // Format time labels (adjust as needed)
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: "CPU",
                    nameLocation: 'middle',
                    nameGap: 35,
                    min: 0,
                    max: 100,
                    axisLabel: {
                        formatter: function(value) {
                            return value.toFixed(0) + '%'; // Prevent separators for RAM and add 'GB'
                        }
                    }
                },
                {
                    type: 'value',
                    name: "RAM",
                    nameLocation: 'middle',
                    nameGap: 50,
                    min: 0,
                    max: _data["maxRam"],
                    axisLabel: {
                        formatter: function(value) {
                            return value.toFixed(0) + ' GB'; // Prevent separators for RAM and add 'GB'
                        }
                    }
                }
            ],
            series: [
                {
                    name: "CPU",
                    type: 'line',
                    color: info.main,
                    data: _data["cpu"].map((val, index) => [_data["timestamps"][index], val]),
                    yAxisIndex: 0
                },
                {
                    name: "RAM",
                    type: 'line',
                    color: primary.main,
                    data: _data["ram"].map((val, index) => [_data["timestamps"][index], val]),
                    yAxisIndex: 1
                }
            ]
        });
    }, [_data]);

    return <>{option && <ReactEcharts option={option} style={{ height: height, width: "100%" }} />}</>
}

export default LineChart;