
import ArgonBox from "components/ArgonBox";


import Editor from '@monaco-editor/react';
import { Card, CircularProgress, Dialog, DialogContent, DialogTitle, styled, Tooltip, tooltipClasses } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Nodes } from "api/OrchestratorApi/Nodes";
import { useEffect, useState } from "react";
import YAML from 'yaml';
import { Save } from "@mui/icons-material";
import ArgonButton from "components/ArgonButton";
import { enqueueSnackbar } from "notistack";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

function EditNodeConfigDialog({ host, nodeId, closeDialog }) {

  const [data, setData] = useState(null);
  const [isDataValid, setIsDataValid] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const getConfig = async () => {
    var response = await Nodes.getNodeConfig(host, nodeId);
    setData(response.data.data)
    setIsDataValid(true)
  }

  const save = async () => {
    setIsSaving(true);
    try {
      var response = await Nodes.saveNodeConfig(host, nodeId, YAML.stringify(data))
      console.log(response)
      enqueueSnackbar('Node configuration updated!', { variant: 'success' })
      closeDialog();
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Failed to update node configuration', { variant: 'error' })
    } finally {
      setIsSaving(false);
    }
  }

  useEffect(() => {
    if (nodeId == null) return;
    getConfig()
  }, [nodeId])

  const handleChange = (val) => {
    try {
      let obj = YAML.parse(val);
      setData(obj);
      setIsDataValid(true)
    } catch (err) {
      console.log(`${err.name}: ${err.message}`);
      setIsDataValid(false)
    }
  };

  let body = <Grid container justifyContent="center" sx={{ marginTop: 20, marginBottom: 20 }}>
    <CircularProgress></CircularProgress>
  </Grid>

  if (data != null) {
    body = <Editor
      height="calc(100vh - 20vh)"
      // value={YAML.stringify(data)}
      onChange={handleChange}
      defaultLanguage="yaml"
      defaultValue={data}
      theme="vs"
    />
  }

  return [
    <DialogTitle
      key="title"
      textAlign="center"
      sx={{ position: "relative", paddingRight: "40px" }} // Ensure padding for the icon
    >
      {"Edit Node Config"}

      {/* Gear Icon on the Top Right */}

      <ArgonButton iconOnly disabled={!isDataValid || isSaving} variant="contained" color="primary" size="medium" sx={{
        position: "absolute",
        top: "8px",
        right: "8px"
      }} onClick={save}>
        <NoMaxWidthTooltip title="Save" placement="top">
          <Save />
        </NoMaxWidthTooltip>
      </ArgonButton>


    </DialogTitle>,

    <DialogContent key="content">
      {body}
    </DialogContent>
  ]
}

export default EditNodeConfigDialog;
