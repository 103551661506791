import apiClient from "api/OrchestratorApi";

const Nodes = {
  getNodeConfig: (url, nodeId) => apiClient.get("http://localhost:8080/nodes/config?nodeId=" + nodeId),
  saveNodeConfig: (url, nodeId, config) => apiClient.post("http://localhost:8080/nodes/config?nodeId=" + nodeId, config, {
    headers: {
      'Content-Type': 'text/plain'
    }
  }),
  getNodes: (url) => apiClient.get("http://localhost:8080/nodes/list"),
  getAllNodesMetrics: (url) => apiClient.get("http://localhost:8080/nodes/getMetrics"),
}

export {
  Nodes
};
