import { Navigate, useLocation } from "react-router-dom";

import ArgonBox from "components/ArgonBox";
import Footer from "components/Footer";
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

import useAuth from "utils/useAuth";

import { Card, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, styled, Tooltip, tooltipClasses } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { UsersApi } from "../../api/BackendApi/Users";
import { Nodes } from "api/OrchestratorApi/Nodes";

import Editor from '@monaco-editor/react';
import YAML from 'yaml';
import ArgonTypography from "components/ArgonTypography";
import { Cloud, Computer, Save, Settings } from "@mui/icons-material";
import LineChart from "./components/LineChart";
import EditNodeConfigDialog from "./components/EditNodeConfigDialog";
import ArgonButton from "components/ArgonButton";

function OrchestratorMgmt() {

  const isLoggedIn = useAuth().ensureLoggedIn();

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }


  const location = useLocation();
  const [info, setInfo] = useState(location.state.info);
  const [host, setAddress] = useState(info.ingresses[0].host);

  const [nodes, setNodes] = useState(null)
  const [metrics, setMetrics] = useState(null)

  const [user, setUser] = useState(null);

  const [editingNodeId, setEditingNodeId] = useState(null)

  const [chartReadyMetrics, setChartReadyMetrics] = useState(null)

  const loadData = async () => {
    let userResponse = await UsersApi.getMe();
    setUser(userResponse.data.user);

    var response = await Nodes.getNodes(host);
    setNodes(response.data.data)

    var metricsResponse = await Nodes.getAllNodesMetrics(host);
    console.log(metricsResponse.data)
    setMetrics(metricsResponse.data.data)

    console.log("Information loaded")
  }

  const getNodesCards = () => {
    let nodeElements = []

    var cloudNodeAdded = false;

    for (var node of nodes) {
      let nodeId = node.id
      let chartData = chartReadyMetrics[nodeId]

      var icon = <Computer sx={{ marginTop: "7px", marginRight: "10px" }} />;
      if (node.isCloudNode) {
        if (cloudNodeAdded) continue;

        icon = <Cloud sx={{ marginTop: "5px", marginRight: "10px" }} />;
        cloudNodeAdded = true;
      }

      var capabilityBlock = []

      var i = 0;
      for (var capability of node.capabilities) {
        capabilityBlock.push(<Grid item sx={{ fontSize: '14px' }} key={"capability-" + i}>{capability.name}</Grid>)
        i++
      }

      nodeElements.push(<Grid item key={"node-" + nodeElements.length} xs={12} sm={6}>
        <Card style={{ position: "relative" }}>
          {/* Gear Icon */}
          <IconButton
            style={{
              position: "absolute",
              top: "-2px",
              right: "-2px",
              zIndex: 999
            }}
            onClick={() => setEditingNodeId(nodeId)}
          >
            <Settings />
          </IconButton>

          <ArgonBox p={1} pl={2}>
            <Grid container direction={"row"} columns={10}>
              <Grid item xs={2}>
                <Grid container direction={"column"}>
                  <Grid item>
                    <Grid container direction={"row"}>
                      {icon}
                      <ArgonTypography>{node.name}</ArgonTypography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction={"column"} style={{ overflowY: "auto", maxHeight: "200px", display: "block" }}>
                      {capabilityBlock}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <LineChart metrics={chartData} height="230px" />
              </Grid>
            </Grid>
          </ArgonBox>
        </Card>
      </Grid>)
    }

    return nodeElements;
  }

  useEffect(() => {
    if (metrics == null) return;

    var chartReady = {}

    for (var nodeId of Object.keys(metrics)) {
      var values = metrics[nodeId]
      if (values == null || values.length == 0) {
        continue;
      }

      var timestamps = []
      var cpuUsage = []
      var ramUsage = []
      var maxRam = (values[0].totRAM / 1000000000).toFixed(0)

      for (var value of values) {
        timestamps.push(value.timestamp)
        cpuUsage.push(value.cpu.toFixed(2))
        ramUsage.push((value.usedRAM / 1000000000).toFixed(0))
      }

      chartReady[nodeId] = {
        timestamps: timestamps,
        cpu: cpuUsage,
        ram: ramUsage,
        maxRam: maxRam
      }
    }

    setChartReadyMetrics(chartReady)
  }, [metrics])

  useEffect(() => {
    loadData();
  }, []);

  let body = <Grid container justifyContent="center" sx={{ marginTop: 40, marginBottom: 20 }}>
    <CircularProgress></CircularProgress>
  </Grid>

  if (user != null && nodes != null && chartReadyMetrics != null) {
    /*<ArgonBox mt={10} mb={3}>
        <Card sx={{ display: "flex", height: "calc(100vh - 20vh)", paddingTop: "15px" }}>
          <Editor
            // value={YAML.stringify(data)}
            onChange={handleChange}
            defaultLanguage="yaml"
            defaultValue={data}
            theme="vs"
          />
        </Card>
      </ArgonBox>*/
    body = (
      <Grid container spacing={2}>
        {getNodesCards()}
      </Grid>
    );
  }

  return (
    <DashboardLayout>
      <Header />
      <Dialog
        open={editingNodeId != null}
        onClose={() => setEditingNodeId(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <EditNodeConfigDialog host={host} nodeId={editingNodeId} closeDialog={() => {
          setEditingNodeId(null)
          loadData()
        }} />
      </Dialog>
      <ArgonBox mt={5} mb={3}>
        <Grid container spacing={2}>
          {body}
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrchestratorMgmt;
