import axios from "axios";

const apiClient = axios.create();

apiClient.interceptors.request.use(function (config) {
  console.log("Intercepted")
  config.headers.Authorization = "Reply.96";
  config.headers.hubId = "mgmt";
  console.log(config)
  return config;
});

export default apiClient;
